.pd_flexContainer {
  display: flex;
  /* align-items: center;  세로 중앙 정렬 */
  justify-content: center; /* 가로 중앙 정렬 (필요한 경우) */
  height: 100%; /* 컨테이너의 높이 지정 */
}

.pd_imageStyle {
  width: 100%;
  height: auto;
  /* border-radius: 8px; /* 모서리 둥글게 */
}
