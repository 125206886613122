.cp_StepTextSx {
  font-size: 0.875rem !important;
  position: absolute;
  /* width: 100px; */
  width: auto;
  /* left: -75%; */
  left: -50%;
  right: -50%;
  textalign: center;
  /* top: 87%; */
  /* bottom: -17px; */
  top: 40px;
  text-align: center;
  textoverflow: ellipsis;
  whitespace: nowrap;
  margin: 0 auto;
  /* display: flex */
  /* justify-content: center; */
  /* align-items: center; */
  /* align-content: center; */
  /* justify-items: center;*/
}

.cp_StepBoxSx {
  display: flex;
  flexdirection: column;
  alignitems: center;
  cursor: pointer;
  position: relative;
}

/* .cp_moveTextSx {
  margin-top: 40px; 
  margin-bottom: 2px;
  font-size: 0.875rem !important;
}

.cp_moveBtnSx {
  margin-left: 8px;
  text-decoration: underline !important;
  text-underline-offset: 3px;
  margin-top: -4px;
} */
